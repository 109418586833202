import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ListComputeInstancesView, NewComputeInstanceView, ManageComputeInstanceView } from './components/Compute';
import { MainBody, NotFoundView } from './components/Main';
import { NewKeyPairInstanceView, ListKeyPairInstancesView, ManageKeyPairInstanceView } from './components/Keypairs';
import { SessionInstanceView } from './components/Session';
import { Container, Menu, Image, Button} from 'semantic-ui-react'


function App() {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setUser(data);
          break;
        case "signOut":
          setUser(null);
          break;
        case "customOAuthState":
          setCustomState(data);
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => {
        setUser(currentUser);
      })
      .catch((e) => {
      });

    return unsubscribe;
  }, []);


  return (
    <>
      <Menu fixed='top' inverted>
        <Container>
          <Menu.Item as='a' href='/' header><Image size='mini' src='/images/fly.png' style={{ marginRight: '1.5em' }}/>CraneFly</Menu.Item>
          {user ? <>
            <Menu.Item as='a' href='/compute'>Compute</Menu.Item>
            <Menu.Item as='a' href='/keypairs'>Keypairs</Menu.Item>
          </> : null}
          <Menu.Item as='a' position='right'>
            {user ? 
              <Button onClick={() => Auth.signOut()} inverted>Log Out</Button> :
              <Button onClick={() => Auth.federatedSignIn()} inverted>Roku AAD SSO Login</Button>}
          </Menu.Item>
        </Container>
      </Menu>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainBody />}></Route>
          <Route path="/compute" element={<ListComputeInstancesView />}></Route>
          <Route path="/compute/new" element={<NewComputeInstanceView />}></Route>
          <Route path="/compute/:instanceId" element={<ManageComputeInstanceView />}></Route>
          <Route path="/compute/:instanceId/session" element={<SessionInstanceView />}></Route>
          <Route path="/keypairs" element={<ListKeyPairInstancesView />}></Route>
          <Route path="/keypairs/new" element={<NewKeyPairInstanceView />}></Route>
          <Route path="/keypairs/:keyID" element={<ManageKeyPairInstanceView />}></Route>
          <Route path="*" element={<NotFoundView />}></Route>
        </Routes>
      </BrowserRouter>
      </>
  );
}

export default App;
