
import { AWSConfigs } from "./Configurations";
import { Auth } from 'aws-amplify';

const baseUrl = AWSConfigs.aws_api_gateway;

async function sendRequest(token, path, method = "GET", body_parameters = null, alive = false) {
    let fetchConfigurations = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        keepalive: alive
      }
    if (body_parameters) {
        fetchConfigurations.body = JSON.stringify(body_parameters);
    }
    console.log(baseUrl + path);
    const response = await fetch((baseUrl + path), fetchConfigurations);
    return response.json(); // parses JSON response into native JavaScript objects
}

export {sendRequest};
