import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Table, Header, Modal, Popup, Dimmer, Loader, Image, Segment, Message, Divider, Icon } from 'semantic-ui-react'
import { useNavigate, useParams } from "react-router-dom";
import { sendRequest } from "../utils/APIHelper";

function KeypairTableActionRow(props) {

    const [keypairDeleteModalFlag, setKeypairDeleteModalFlag] = useState(false)
    const [deleteKeypairProgressFlag, setDeleteKeypairProgressFlag] = useState(false)
    const [deleteKeypairErrorFlag, setDeleteKeypairErrorFlag] = useState(false)
    const [deleteKeypairSuccessFlag, setDeleteKeypairSuccessFlag] = useState(false)
    const navigate = useNavigate();

    async function deleteKeypair(keypairID) {
        setDeleteKeypairProgressFlag(true);
        const user = await Auth.currentAuthenticatedUser();
        let token = user.signInUserSession.idToken.jwtToken;
        const response = await sendRequest(token, "/keypairs/" + keypairID, "DELETE");
        console.log("[API] Keypair Deletion - " + keypairID);
        if (response['status'] == 'success') {
            setDeleteKeypairSuccessFlag(true);
            setDeleteKeypairProgressFlag(false);
        } else {
            setDeleteKeypairProgressFlag(false);
            setDeleteKeypairErrorFlag(true);
        }
    }

    let content = (
        <Table.Row>
            <Table.Cell colSpan="5" textAlign='right'>
                <Modal
                    onClose={() => setKeypairDeleteModalFlag(false)}
                    onOpen={() => setKeypairDeleteModalFlag(true)}
                    open={keypairDeleteModalFlag}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    trigger={<Button color='grey'>Delete</Button>}
                >
                    <Modal.Header>Confirmation</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>Deleting Keypair</Header>
                            <p>Deleting the keypair removes the corresponding public key from CraneFly's enviornment. Instance(s) that are associated with this keypair will not be affected. This action simply prevents you from associating future instances with this keypair. <br></br><br></br>Are you sure you want to delete <b>{props.keypair['KeyPairId']}</b>?</p>
                            {deleteKeypairErrorFlag ?
                                <Message negative>
                                    <Message.Header>Sorryyyy!</Message.Header>
                                    <p>An error has occurred when deleting the keypair. Please raise a support ticket.</p>
                                </Message> : null
                            }

                            {deleteKeypairSuccessFlag ?
                                <Message positive>
                                    <Message.Header>Yayyyy!</Message.Header>
                                    <p>Your keypair has been successfully deleted</p>
                                </Message> : null
                            }

                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        {deleteKeypairSuccessFlag ?
                            <Button content="Done" color='green' onClick={() => window.location.reload(false)} /> : <>
                                {deleteKeypairErrorFlag ? <Button color='grey' onClick={() => {
                                    setKeypairDeleteModalFlag(false);
                                    setDeleteKeypairErrorFlag(false);
                                }}>Okay</Button> :
                                    <>
                                        <Button color='grey' onClick={() => {
                                            setKeypairDeleteModalFlag(false);
                                            setDeleteKeypairErrorFlag(false);
                                        }}
                                            disabled={deleteKeypairProgressFlag}>Nope</Button>
                                        {deleteKeypairProgressFlag ?
                                            <Button loading color='red'>loading</Button> :
                                            <Button content="Yes, delete it!" color='red' onClick={() => deleteKeypair(props.keypair['KeyPairId'])} />}
                                    </>
                                }
                            </>
                        }
                    </Modal.Actions>
                </Modal>
                <Button color='blue' content='Manage' onClick={() => { navigate("/keypairs/" + props.keypair['KeyPairId']) }} />
            </Table.Cell>
        </Table.Row>)
    return content
}

function ListKeyPairInstancesView() {
    const navigate = useNavigate();
    const [keypairsFetchResponse, setKeypairsFetchResponse] = useState(null);
    useEffect(() => {
        async function startFetching() {
            const user = await Auth.currentAuthenticatedUser();
            let token = user.signInUserSession.idToken.jwtToken;
            const response = await sendRequest(token, "/keypairs", "GET");
            console.log("[API] Fetching Keypairs");
            if (!ignore) {
                setKeypairsFetchResponse(response);
            }
        }
        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        }
    }, []);

    let keypairsTableContent = [];
    let content = null;
    let instanceCount = 0;
    let tableContent = (
        <Segment>
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
    );
    if (keypairsFetchResponse != null && keypairsFetchResponse['status'] == "success") {
        instanceCount = keypairsFetchResponse['results'].length;
        if (instanceCount > 0) {
            for (let i = 1; i <= instanceCount; i++) {
                let keypair = keypairsFetchResponse['results'][i - 1];
                let tags = {};
                keypair['Tags'].forEach((tag) => {
                    tags[tag['Key']] = tag['Value'];
                })
                keypairsTableContent.push(
                    <Table.Row>
                        <Table.Cell textAlign='center'>{i}</Table.Cell>
                        <Table.Cell textAlign='left'>
                            <b><u>{keypair['KeyName']}</u></b>
                            <i><p>Associated to <b>{tags['EC2-Alias']}</b> ({tags['EC2-Instance']})</p></i>
                        </Table.Cell>
                        {/* <Table.Cell textAlign='center'>
                            <p><b>{tags['EC2-Alias']}</b> ({tags['EC2-Instance']})</p>
                        </Table.Cell> */}
                        <Table.Cell textAlign='center'>{keypair['KeyType']}</Table.Cell>
                    </Table.Row>)
                // keypairsTableContent.push(<KeypairTableActionRow keypair={keypair} />)
            }
            tableContent = <>
                <Table celled>
                    <Table.Header>
                        <Table.Row >
                            <Table.HeaderCell width={1}>S/N</Table.HeaderCell>
                            <Table.HeaderCell width={14} textAlign='left'>Keypair Instance</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Type</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {keypairsTableContent}
                    </Table.Body>
                </Table>
            </>
        } else {
            tableContent = (
                <div class="ui message">
                    <div class="header">
                        Hmmm!!
                    </div>
                    <p>You currently do not have any instances with us! Creating a compute instance will automatically create an associate keypair!</p>
                </div>
            )
        }
    }

    let mainContent = (
            <div class="ui grid">
                <div class="row">
                    <div class="four wide column"></div>
                    <div class="eight wide ui column">
                    <br></br><br></br><br></br>
                        <h3 class="ui center aligned dividing header">Your Keypair Instance(s) - {instanceCount} in total</h3>
                    </div>
                    <div class="four wide column"></div>
                </div>

                <div class="row">
                    <div class="four wide column"></div>
                    <div class="eight wide column"> 
                        {tableContent}
                    </div>
                    <div class="four wide column"></div>
                </div>
            </div>
    );
    return mainContent
}

function NewKeyPairInstanceView() {
    const [keypairCreateModalFlag, setKeypairCreateModalFlag] = useState(false);
    const [createKeypairErrorFlag, setCreateKeypairErrorFlag] = useState(false);
    const [createKeypairSuccessFlag, setCreateKeypairSuccessFlag] = useState(false);
    const [privateKeyContent, setPrivateKeyContent] = useState('');
    const navigate = useNavigate();
    async function createKeyPair(event) {
        event.preventDefault();
        setKeypairCreateModalFlag(true);
        const user = await Auth.currentAuthenticatedUser();
        let token = user.signInUserSession.idToken.jwtToken;
        console.log("[API] Keypair Creation");
        console.log("[Data] Alias: " + event.target['alias'].value);
        console.log("[Data] Descrption: " + event.target['description'].value);
        const response = await sendRequest(token, "/keypairs", "POST", { "alias": event.target['alias'].value, "description": event.target['description'].value });
        if (response['status'] == 'success') {
            setCreateKeypairSuccessFlag(true);
            setPrivateKeyContent(response['results']['KeyMaterial']);
        } else {
            setCreateKeypairErrorFlag(true);
        }
    }

    async function downloadPEMFile() {
        const element = document.createElement("a");
        const file = new Blob([privateKeyContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.target = "_blank";
        element.download = "rsa_private_key.pem";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    let modal = <Modal
        onClose={() => {
            setKeypairCreateModalFlag(false);
        }}
        onOpen={() => setKeypairCreateModalFlag(true)}
        open={keypairCreateModalFlag}
        closeOnEscape={false}
        closeOnDimmerClick={false}
    >
        <Header content='Creating your keypair' />
        <Modal.Content>
            {createKeypairSuccessFlag ?
                <>
                    <Message positive>
                        <Message.Header>Yayyyy!</Message.Header>
                        <p>Your keypair has been successfully created! Please save your private key as CraneFly will no longer display it again once this window has been closed.</p>
                    </Message> <br></br><b>Private Key (PEM)</b><Divider />
                    <Popup content='Copied to clipboard' on="click" trigger={
                        <code class="bash" onClick={() => { navigator.clipboard.writeText(privateKeyContent); }}>{privateKeyContent}</code>}
                    />

                </> : <>

                    {createKeypairErrorFlag ?
                        <>
                            <Message negative>
                                <Message.Header>Oh noess!</Message.Header>
                                <p>We couldn't create the keypair. Please raise a support ticket. So sorry about it!</p>
                            </Message>
                        </> :
                        <>
                            <Segment>
                                <Dimmer active inverted>
                                    <Loader inverted>Creating keypair...</Loader>
                                </Dimmer>
                                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                            </Segment>
                        </>



                    }
                </>

            }

        </Modal.Content>
        <Modal.Actions>

            {createKeypairSuccessFlag ?
                <>
                    <Popup content='Copied' trigger={
                        <Button color='grey' onClick={() => {
                            navigator.clipboard.writeText(privateKeyContent);
                        }}>Copy to clipboard</Button>} />
                    <Button color='blue' onClick={() => {
                        downloadPEMFile();
                    }}>Download</Button>
                    <Button color='green' onClick={() => {
                        navigate("/keypairs");
                    }}>Done</Button>
                </> : <>

                    {createKeypairErrorFlag ?
                        <>
                            <Button color='grey' onClick={() => {
                                setKeypairCreateModalFlag(false);
                                setCreateKeypairErrorFlag(false);
                            }}>Okay</Button>
                        </> : null
                    }
                </>
            }
        </Modal.Actions>
    </Modal>
    let content =
            <div class="ui grid">
                <div class="row">
                    <div class="three wide column"></div>
                    <div class="ten wide ui column">
                        <br></br>
                        <h3 class="ui center aligned dividing header">Create New Keypair Instance</h3>
                        <div class="ui message">
                            <div class="header">
                                Service Announcement
                            </div>
                            <p>Cranefly supports only a limited set of Linux Operating Systems. Thus, keypairs that are created as 2048-bit RSA cryptographic by default.</p>
                        </div>
                    </div>
                    <div class="three wide column"></div>
                </div>
                <div class="row">
                    <div class="three wide column"></div>
                    <div class="ten wide column">
                        <form class="ui segment form left aligned" onSubmit={createKeyPair}>
                            <div class="field" >
                                <label>Alias (64 Characters)</label>
                                <textarea rows="1" placeholder="How would you name this instance?" id="alias" maxlength="64" required></textarea>
                            </div>
                            <div class="field">
                                <label>Description (256 Characters)</label>
                                <textarea rows="2" placeholder="How would you describe this instance?" id="description" maxlength="256" required></textarea>
                            </div>


                            <div class="ui container center aligned">
                                <br></br>
                                {modal}
                                <Button color='green' type="submit">Create Keypair</Button>
                            </div>
                        </form>
                    </div>

                    <div class="four wide column"></div>
                </div>
                <div class="row">
                    <div class="five wide column"></div>
                    <div class="six wide column"><br></br><br></br></div>
                    <div class="five wide column"></div>
                </div>
            </div>;
    return content
}

function ManageKeyPairInstanceView() {
    const [keypairUpdateModalFlag, setKeypairUpdateModalFlag] = useState(false);
    const [updateKeypairErrorFlag, setUpdateKeypairErrorFlag] = useState(false);
    const [updateKeypairSuccessFlag, setUpdateKeypairSuccessFlag] = useState(false);
    const [keypair, setKeypair] = useState('');
    const [keypairTags, setKeypairTags] = useState(null);
    const { keyID } = useParams();
    useEffect(() => {
        async function startFetching() {
            const user = await Auth.currentAuthenticatedUser();
            let token = user.signInUserSession.idToken.jwtToken;
            const response = await sendRequest(token, "/keypairs/" + keyID, "GET");
            console.log("[API] Fetching Keypair Details");
            if (!ignore) {
                setKeypair(response);
                let tags = {};
                response['results']['Tags'].map(data => tags[data['Key']] = data['Value']);
                setKeypairTags(tags);
            }
        }
        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        }
    }, []);
    const navigate = useNavigate();
    async function updateKeypair(event) {
        event.preventDefault();
        setKeypairUpdateModalFlag(true);
        const user = await Auth.currentAuthenticatedUser();
        let token = user.signInUserSession.idToken.jwtToken;
        console.log("[API] Keypair Update");
        console.log("[Data] Alias: " + event.target['alias'].value);
        console.log("[Data] Descrption: " + event.target['description'].value);
        const response = await sendRequest(token, "/keypairs/" + keyID, "PATCH", { "alias": event.target['alias'].value, "description": event.target['description'].value });
        if (response['status'] == 'success') {
            setUpdateKeypairSuccessFlag(true);
        } else {
            setUpdateKeypairErrorFlag(true);
        }
    }

    let modal = <Modal
        onClose={() => {
            setKeypairUpdateModalFlag(false);
        }}
        onOpen={() => setKeypairUpdateModalFlag(true)}
        open={keypairUpdateModalFlag}
        closeOnEscape={false}
        closeOnDimmerClick={false}
    >
        <Header content='Updating your keypair' />
        <Modal.Content>
            {updateKeypairSuccessFlag ?
                <>
                    <Message positive>
                        <Message.Header>Yayyyy!</Message.Header>
                        <p>Your keypair has been successfully updated!</p>
                    </Message>
                </> : <>
                    {updateKeypairErrorFlag ?
                        <>
                            <Message negative>
                                <Message.Header>Oh noess!</Message.Header>
                                <p>We couldn't update the keypair. Please raise a support ticket. So sorry about it!</p>
                            </Message>
                        </> :
                        <>
                            <Segment>
                                <Dimmer active inverted>
                                    <Loader inverted>Updating keypair...</Loader>
                                </Dimmer>
                                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                            </Segment>
                        </>
                    }
                </>
            }
        </Modal.Content>
        <Modal.Actions>

            {updateKeypairSuccessFlag ?
                <>
                    <Button color='green' onClick={() => {
                        navigate("/keypairs");
                    }}>Done</Button>
                </> : <>

                    {updateKeypairErrorFlag ?
                        <>
                            <Button color='grey' onClick={() => {
                                setKeypairUpdateModalFlag(false);
                                setUpdateKeypairErrorFlag(false);
                            }}>Okay</Button>
                        </> : null
                    }
                </>
            }
        </Modal.Actions>
    </Modal>
    let subContent = (
        <Segment>
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
    );

    if (keypair != null && keypair['status'] == 'success') {
        subContent =
            <form class="ui segment form left aligned" onSubmit={updateKeypair}>
                <div class="field" >
                    <label>Alias (64 Characters)</label>
                    <textarea rows="1" placeholder="How would you name this instance?" id="alias" maxLength="64" required defaultValue={keypairTags['Alias']}></textarea>
                </div>
                <div class="field">
                    <label>Description (256 Characters)</label>
                    <textarea rows="2" placeholder="How would you describe this instance?" id="description" maxLength="256" required defaultValue={keypairTags['Description']}></textarea>
                </div>
                <div class="ui container center aligned">
                    <br></br>
                    {modal}
                    <Button color='green' type="submit">Update Keypair</Button>
                </div>
            </form>
    }

    let mainContent =
            <div class="ui grid">
                <div class="row">
                    <div class="three wide column"></div>
                    <div class="ten wide ui column">
                    <br></br><br></br><br></br>
                        <h3 class="ui center aligned dividing header">Manage Keypair Instance - {keyID}</h3>
                    </div>
                    <div class="three wide column"></div>
                </div>
                <div class="row">
                    <div class="three wide column"></div>
                    <div class="ten wide ui column">
                        {subContent}
                    </div>
                    <div class="three wide column"></div>
                </div>
            </div>;


    return mainContent
}

export { NewKeyPairInstanceView, ListKeyPairInstancesView, ManageKeyPairInstanceView };