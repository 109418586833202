
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';
import { Container, Header, List, Image } from 'semantic-ui-react'


function MainBody() {
    let currentUser = Auth.user;
    let content = 
        <div class="ui grid">
            <div class="row">
                <div class="four wide column"></div>
                <div class="eight wide column">
                <Container text textAlign='center'>
                    <br></br>
                    <h2>Sorry we can't let you in!</h2>
                    <Image src='/images/unauthorized.png' size='medium' centered/>
                    <br></br>
                    <p>This site is for authenticated users only. Please login via your Roku's AAD SSO to access site.</p>
                </Container>
                </div>
                <div class="four wide column"></div>
            </div>
        </div>;
    if (currentUser != null) {
        content =
                <div class="ui grid">
                    <div class="row">
                        <div class="two wide column"></div>
                        <div class="twelve wide column">
                            <br></br><br></br><br></br>
                            <Container text textAlign='left'>
                                <Header as='h2'>Project CraneFly</Header>
                                <p>Project CraneFly is an initiative from Trust Engineering to provide Roku engineers a sandboxed fire-and-forget enviornment to spin up EC2 instances for testing purposes.</p>
                                <p>We understand the need to have an environment for engineers to rapidly create EC2 instances to for rapid prototyping! You create the instances and do your testing while we handle the rest. CraneFly will automatically decomission the EC2 isntances that you provisioned based on the lifespan you have set!</p>
                                <p>We offer harden-ed machine images to be used for provisioning. These images was put through many security lenses to ensure that we can provide a secure instance for you to use!</p>
                                <Header as='h3'>Business Value</Header>
                                <p>In Trust Engineering, we observed developers often provision instances for prototyping and tend to forget it thereafter. The key consequence with these instances is that they often enlarge Roku's attack surface! For instance an exposed EC2 instance with weak SSH configurations (such as password) is prone to password brute-forcing attacks in the wild. This happens often! Potentially, we are creating additional holes in our defense posture! Thus, to help everyone, we would like to shift this burden of security to Trust Engineering and let our Roku engineers do what they do best - to build! </p>
                                <Header as='h3'>Designed with simplicity in mind</Header>
                                <p>Check out the video below to have a look on what CraneFly can do for you!</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/rUPsfKd4jnY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                <br></br>
                                <Header as='h3'>Frequently Asked Questions</Header>
                                <List as='ol'>
                                    <List.Item as='li'><b>Why CraneFly?</b></List.Item><p>It is an initiative from Trust Engineering to build a sandboxed envionment for Roku's engineers to rapidly spin up instances for prototyping. In this manner, we can help to reduce Roku's attack surface while enabling engineers to rapidly innovate! We don't want to lose speed for security!</p>
                                    <List.Item as='li'>
                                        <b>Whats the value to me as an Engineer?</b>
                                        <List.List as='ol'>
                                            <List.Item as='li'>Shift of security burden</List.Item>
                                            <List.Item as='li'>Sandboxed and secure</List.Item>
                                            <List.Item as='li'>Less jira tickets from us</List.Item>
                                        </List.List>
                                    </List.Item>
                                    <List.Item as='li'><b>Who pays the bill?</b></List.Item><p>Let me check with Lily...</p>
                                </List>
                                <Header as='h3'>Talk to us!</Header>
                                <p>Let's make CraneFly better! <a href="https://roku.slack.com/archives/C01BN1WA21M">Slack</a> us to chat about CraneFly and how we can continue to innovate! You can contribute in other ways too! Check out our <a href="https://confluence.portal.roku.com:8443/display/TRUST/CraneFly">Confluence</a> and our <a href="https://gitlab.eng.roku.com/trusteng/vulnerabilitymanagement/cranefly/-/tree/main">repository</a> too!</p> 
                                
                            </Container>
                        </div>
                        <div class="two wide column"></div>
                    </div>
                    <div class="row">
                        <div class="four wide column"></div>
                        <div class="eight wide column"><br></br><br></br></div>
                        <div class="four wide column"></div>
                    </div>
                </div>;

    }
    return (
        <>
            {content}
        </>
    );
}

function NotFoundView() {
    return <>
        <div class="ui grid">
            <div class="row">
                <div class="four wide column"></div>
                <div class="eight wide column">
                <Container text textAlign='center'>
                    <br></br>
                    <h2>Oppppsssieeeee!</h2>
                    <Image src='/images/computer.png' size='medium' centered/>
                    <br></br>
                    <p>We can't find the resource that you are looking for!</p>
                </Container>
                </div>
                <div class="four wide column"></div>
            </div>
        </div>
    </>;
}

export { MainBody, NotFoundView };