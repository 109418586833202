// Azure AD - Prod (Roku)
const AWSConfigs =  {
    "aws_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:7e374a21-12d1-47d7-ac27-d61557b78d68",
    "aws_user_pools_id": "us-east-2_5cshmH7Ph",
    "aws_user_pools_web_client_id": "5d36as8ekt6ligt8779d50idrf",
    "aws_cognito_domain": "cranefly.auth.us-east-2.amazoncognito.com",
    "aws_cognito_redirect_signin": "https://cranefly.trust.roku.com",
    "aws_cognito_redirect_signout": "https://cranefly.trust.roku.com",
    // "aws_cognito_redirect_signin": "http://localhost:3000",
    // "aws_cognito_redirect_signout": "http://localhost:3000",
    "aws_cognito_auth_flow_type": "USER_SRP_AUTH",
    "aws_cognito_oauth_scope": ['email', 'openid'],
    "aws_cognito_oauth_response_type": "code",
    "aws_api_gateway": "https://api.cranefly.trust.roku.com",
};

const AWSCognitoConfigs = {
    identityPoolId: AWSConfigs.aws_cognito_identity_pool_id,
    region: AWSConfigs.aws_region,
    userPoolId: AWSConfigs.aws_user_pools_id,
    userPoolWebClientId: AWSConfigs.aws_user_pools_web_client_id,
    mandatorySignIn: true,
    authenticationFlowType: AWSConfigs.aws_cognito_auth_flow_type,
    oauth: {
      domain: AWSConfigs.aws_cognito_domain,
      scope: AWSConfigs.aws_cognito_oauth_scope,
      redirectSignIn: AWSConfigs.aws_cognito_redirect_signin,
      redirectSignOut: AWSConfigs.aws_cognito_redirect_signout,
      clientId: AWSConfigs.aws_user_pools_web_client_id,
      responseType: AWSConfigs.aws_cognito_oauth_response_type // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }


export {AWSConfigs, AWSCognitoConfigs};