import { useState, useEffect, useRef, memo } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useParams } from "react-router-dom";
import { Dimmer, Image, Segment, Message, Button, Header, Modal, Loader } from 'semantic-ui-react'
import { sendRequest } from "./../utils/APIHelper";

function SessionInstanceView() {
    const { instanceId } = useParams();
    const navigate = useNavigate();
    const [loadingSessionDetails, setLoadingSessionDetails] = useState(true);
    const [sessionDetails, setSessionDetails] = useState(null);
    const [unauthorizedSessionFlag, setUnauthorizedSessionFlag] = useState(false);
    const [closingFlag, setClosingFlag] = useState(false);

    useEffect(() => {
        async function fetchComputeSessionDetails() {
            if (!ignore) {
                const user = await Auth.currentAuthenticatedUser();
                let token = user.signInUserSession.idToken.jwtToken;
                console.log("[API] Session Manager Session Details - " + instanceId);
                const session_manager_details_response = await sendRequest(token, "/sessions/" + instanceId, "POST");
                if (session_manager_details_response['status'] == 'success') {
                    setLoadingSessionDetails(false);
                    setSessionDetails(session_manager_details_response['results']);
                    window.addEventListener('beforeunload', (event)=>terminateSession(event, instanceId, session_manager_details_response['results']['SessionId']));
                } else {
                    setLoadingSessionDetails(false);
                    setUnauthorizedSessionFlag(true);
                }
            }
        }
        let ignore = false;
        fetchComputeSessionDetails();
        return () => {
            ignore = true;
            window.removeEventListener('beforeunload', terminateSession);
        }
    }, []);
    async function terminateSession(e, instanceId, sessionId) {
        e.preventDefault();
        setClosingFlag(true);
        if (!unauthorizedSessionFlag) {
            const user = await Auth.currentAuthenticatedUser();
            let token = user.signInUserSession.idToken.jwtToken;
            await sendRequest(token, "/sessions/" + instanceId + "/" + sessionId, "DELETE", true);
        }
    }

    let loadingContent = (
        <Segment>
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
    );
    let mainContent = null;

    if (unauthorizedSessionFlag) {
        mainContent = (
            <>
                <Message negative>
                    <Message.Header>Pshhhh!</Message.Header>
                    <p>You have no access to this instance!</p>
                </Message>
            </>
        );
    } else if (!loadingSessionDetails) {
        mainContent = (
            // <MemoSessionTerminal />
            <SessionTerminal token={sessionDetails['TokenValue']} wsurl={sessionDetails['StreamUrl']} sessionId={sessionDetails['SessionId']} instanceId={instanceId} />
        );
    }
    return (
        <div class="ui grid">
            <div class="row">
                <div class="three wide column"></div>
                <div class="ten wide ui column">
                    <br></br><br></br><br></br>
                    <h3 class="ui center aligned dividing header">Session Manager - {instanceId}</h3>
                </div>
                <div class="three wide column"></div>
            </div>
            <div class="row">
                <div class="three wide column"></div>
                <div class="ten wide column">
                    {loadingSessionDetails ? loadingContent : mainContent}
                    <br></br>
                </div>
                <div class="three wide column"></div>
            </div>
            <div class="row">
                <Modal
                    onClose={() => { }}
                    onOpen={() => { }}
                    open={closingFlag}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Header content={'Terminating session..'} />
                    <Modal.Content>
                        <Segment>
                            <Dimmer active inverted>
                                <Loader inverted>Terminating</Loader>
                            </Dimmer>
                            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                        </Segment>
                    </Modal.Content>
                </Modal>
            </div>
        </div>
    );
}

function SessionTerminal(props) {
    const childFrame = useRef(null);
    const navigate = useNavigate();
    const [terminatingSessionFlag, setTerminatingSessionFlag] = useState(false);
    const [terminatingSessionSuccessFlag, setTerminatingSessionSuccessFlag] = useState(false);
    const [terminatingSessionErrorFlag, setTerminatingSessionErrorFlag] = useState(false);
    async function terminateSession() {
        const user = await Auth.currentAuthenticatedUser();
        let token = user.signInUserSession.idToken.jwtToken;
        console.log("[API] Terminate Session - " + props.sessionId);
        setTerminatingSessionFlag(true);
        const delete_session_response = await sendRequest(token, "/sessions/" + props.instanceId + "/" + props.sessionId, "DELETE");
        if (delete_session_response['status'] == 'success') {
            setTerminatingSessionSuccessFlag(true);
        } else {
            setTerminatingSessionErrorFlag(true);
        }
    }
    function configureParentFrame() {
        window.addEventListener('message', async function (event) {
            try {
                let results = JSON.parse(event.data);
                if ('operation' in results && results['operation'] == 'terminate') {
                    await terminateSession();
                }
            } catch (err) {

            }
        });
        let token = props.token;
        let wsurl = props.wsurl;
        let sessionId = props.sessionId;
        childFrame.current.contentWindow.postMessage(JSON.stringify({
            'token': token,
            'wsurl': wsurl,
            'sessionId': sessionId,
            'operation': 'setup'
        }), "*");
    }

    return (
        <>
            <div onLoad={() => { configureParentFrame() }}>
                <iframe ref={childFrame} src={"/ssm-terminal-window.html?token"} width={'100%'} height={420} frameBorder="0"></iframe>
            </div>
            <Modal
                onClose={() => { setTerminatingSessionFlag(false) }}
                onOpen={() => {
                    setTerminatingSessionFlag(true);
                }}
                open={terminatingSessionFlag}
                closeOnEscape={false}
                closeOnDimmerClick={false}
            >
                <Header content={'Terminating Session...'} />
                <Modal.Content>
                    {terminatingSessionSuccessFlag ?
                        <>
                            <Message positive>
                                <Message.Header>Yayyyy!</Message.Header>
                                <p>Session has terminated successfully!</p>
                            </Message>
                        </> :
                        <>
                            {terminatingSessionErrorFlag ?
                                <Message negative>
                                    <Message.Header>Oh noess!</Message.Header>
                                    <p>We couldn't terminate the session! Please raise a support ticket. So sorry about it!</p>
                                </Message>
                                :
                                <Segment>
                                    <Dimmer active inverted>
                                        <Loader inverted>Loading</Loader>
                                    </Dimmer>
                                    <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                </Segment>
                            }
                        </>
                    }
                </Modal.Content>
                <Modal.Actions>
                    {terminatingSessionSuccessFlag ?
                        <>
                            <Button color='green' onClick={() => { navigate(-1) }}>Done</Button>
                        </> :
                        <>
                            {terminatingSessionErrorFlag ?
                                <Button color='grey' onClick={() => {
                                    setTerminatingSessionFlag(false);
                                    setTerminatingSessionErrorFlag(false);
                                }}>Okay</Button>
                                :
                                null
                            }
                        </>
                    }
                </Modal.Actions>
            </Modal>
        </>

    )
}

// const MemoSessionTerminal = memo(SessionTerminal)

export { SessionInstanceView };