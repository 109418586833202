let validator = {
    'lifespan': {
        'placeholder': 'Lifetime of the instance (days)',
        'helper' : 'Number of days in which the instance will live for'
    },
    'cidr': {
        'placeholder': 'What is the CIDR block to be used?',
        'helper' : 'Valid CIDR looks like this 0.0.0.0/0 (for all), 123.23.52.10/30 (XXX.XXX.XXX.XXX/XX) and etc',
        'message' : 'The CIDR block(s) looks funny, please ensure that they are properly formatted',
        're': new RegExp("^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$"),
        "isValid": function(input) {
            return validator.cidr.re.test(input)
        },
        'maxLength': 18
    },
    'port': {
        'placeholder': 'What is the port in used?',
        'helper' : 'Port value(s) ranges from 0 to 65535',
        'message' : 'The port number(s) ain\'t right, please ensure that they are between 0 to 65535 (numbers only)',
        're': new RegExp("^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$"),
        "isValid": function(input) {
            return validator.port.re.test(input)
        },
        'maxLength': 5
    },
    'description': {
        'placeholder': 'How would you best describe this resource?',
        'helper' : 'Any meaningful text up to a length of 256 characters',
        'message' : 'The description seems a little off, please keep it to 256 characters',
        're': new RegExp("^.{0,256}$"),
        "isValid": function(input) {
            return validator.description.re.test(input)
        },
        'maxLength': 256
    },
    'alias': {
        'placeholder': 'How would you best name this resource?',
        'helper' : 'Any meaningful text up to a length of 64 characters',
        'message' : 'The alias seems a little off, please keep it to 64 characters',
        're': new RegExp("^.{0,64}$"),
        "isValid": function(input) {
            return validator.alias.re.test(input)
        },
        'maxLength': 64
    },
    'portChecker': {
        'isValidPortCombination': (startPort, endPort)=> {
            return startPort <= endPort;
        },
        'message': 'The start port value should be smaller than or equals to end port value!'
    }
    
}

const protocolSelection = [
    {
        key: 'tcp',
        text: 'TCP',
        value: 'tcp',
    },
    {
        key: 'udp',
        text: 'UDP',
        value: 'udp',
    },
    {
        key: 'icmp',
        text: 'ICMP',
        value: 'icmp',
    }
]

export {validator, protocolSelection};