import React from 'react';
import { Auth } from 'aws-amplify';
import { Tab, TableRow } from 'semantic-ui-react'
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import { Select, Dropdown, Form, Button, Table, Header, Modal, Popup, Dimmer, Loader, Image, Segment, Message, Divider, Icon } from 'semantic-ui-react'
import { sendRequest } from "../utils/APIHelper";
import { protocolSelection, validator } from "../utils/ComponentHelper";

function ListComputeInstancesView() {
    const navigate = useNavigate();
    const [computeInstances, setComputeInstances] = useState(null);
    const [computeInstancesTags, setComputeInstancesTags] = useState(null);
    const [numberOfComputeInstances, setNumberOfComputeInstances] = useState(0);
    useEffect(() => {
        async function fetchComputeInstances() {
            const user = await Auth.currentAuthenticatedUser();
            let token = user.signInUserSession.idToken.jwtToken;
            const response = await sendRequest(token, "/compute", "GET");
            console.log("[API] Fetching Compute Details");
            if (!ignore) {
                if (response['status'] == 'success') {
                    setComputeInstances(response['results']);
                    setNumberOfComputeInstances(response['results'].length);
                    let tags = {};
                    response['results'].forEach((computeInstance) => {
                        tags[computeInstance['InstanceId']] = {};
                        computeInstance['Tags'].forEach((tag) => {
                            tags[computeInstance['InstanceId']][tag['Key']] = tag['Value'];
                        })
                    });
                    setComputeInstancesTags(tags);
                }
            }
        }
        let ignore = false;
        fetchComputeInstances();
        return () => {
            ignore = true;
        }
    }, []);

    let tableContent = (
        <Segment>
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
    );

    if (computeInstances != null && computeInstancesTags != null) {
        if (numberOfComputeInstances > 0) {
            let tableRows = [];
            for (let i = 1; i <= numberOfComputeInstances; i++) {
                let computeInstance = computeInstances[i - 1];
                let tags = computeInstancesTags[computeInstance['InstanceId']];
                let launchDate = new Date(computeInstance['LaunchTime']);
                let launchDateDisplay = launchDate.toDateString();
                let currentDate = Date.now();
                let elapsedTime = Math.abs(Math.round((currentDate - launchDate) / 1000 / (60 * 60)));
                let elapsedTimeDisplay = elapsedTime + " hours ago";
                if (elapsedTime > 24) {
                    elapsedTime /= 24;
                    elapsedTime = Math.abs(Math.round(elapsedTime));
                    elapsedTimeDisplay = elapsedTime + " days ago";
                }
                let lifeSpan = parseInt(tags['Lifespan']);
                let expiryDate = new Date();
                expiryDate.setDate(launchDate.getDate() + lifeSpan);
                let daysLeft = Math.abs(Math.round((expiryDate - currentDate) / 1000 / (60 * 60)));
                let daysLeftDisplay = daysLeft + " hours";
                if (daysLeft > 24) {
                    daysLeft /= 24;
                    daysLeft = Math.abs(Math.round(daysLeft));
                    daysLeftDisplay = daysLeft + " days";
                }
                tableRows.push(
                    <Table.Row>
                        <Table.Cell textAlign='center'>{i}</Table.Cell>
                        <Table.Cell>
                            <b>{tags['Alias']}</b>
                            <p>{tags['Description']}</p> {
                                computeInstance['NetworkInterfaces'].length > 0 ?
                                    (<>
                                        <p>Public IPv4: {computeInstance['NetworkInterfaces'][0]['Association']['PublicIp']}<br></br>Public IPv4 DNS: <a href={'https://' + computeInstance['NetworkInterfaces'][0]['Association']['PublicDnsName']} target="_blank">{computeInstance['NetworkInterfaces'][0]['Association']['PublicDnsName']}</a></p>
                                        <p>Launched {elapsedTimeDisplay} ago, on {launchDateDisplay}<br></br><i>Automatically terminating in <b>{daysLeftDisplay}</b></i></p>
                                    </>) : <>
                                        <p>All networking association(s) has been removed</p>
                                    </>
                            }

                        </Table.Cell>
                        <Table.Cell textAlign='center'>{computeInstance['State']['Name'].toUpperCase()}</Table.Cell>
                    </Table.Row>)
                if (computeInstance['State']['Name'] == 'running') {
                    tableRows.push(<ComputeInstanceTableActionRow computeInstance={computeInstance} tags={tags} />)
                } else {
                    tableRows.push(
                        <Table.Row>
                            <Table.Cell colSpan="3" textAlign='center'>
                                <p>No actions avaliable</p>
                            </Table.Cell>
                        </Table.Row>
                    )
                }
            }
            tableContent = (
                <table class="ui celled padded table">
                    <thead>
                        <tr>
                            <th class="center aligned">S/N</th>
                            <th>Instance</th>
                            <th class="center aligned">State</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            );
        } else {
            tableContent = (
                <div class="ui message">
                    <div class="header">
                        Hmmm!!
                    </div>
                    <p>You currently do not have any instances with us! Do you want to create an instance?</p>
                </div>
            )
        }
    }

    let mainContent = (
        <>
            <div class="ui grid">
                <div class="row">
                    <div class="three wide column"></div>
                    <div class="ten wide ui column">
                        <br></br><br></br><br></br>
                        <h3 class="ui center aligned dividing header">Your Compute Instance(s) - {numberOfComputeInstances} in total</h3>
                    </div>
                    <div class="three wide column"></div>
                </div>

                <div class="row">
                    <div class="three wide column"></div>
                    <div class="ten wide column">
                        {tableContent}
                        <br></br>
                        <div class="ui right aligned">
                            <button class="ui green button" onClick={() => {
                                navigate("/compute/new");
                            }

                            }>Launch a new instance</button>
                        </div>
                    </div>
                    <div class="three wide column"></div>
                </div>
            </div>
        </>
    );
    return mainContent
}

function NewComputeInstanceView() {
    const navigate = useNavigate();
    const [metaData, setMetaData] = useState(null);
    const [preloadFlag, setPreloadFlag] = useState(true);
    const [preloadErrorFlag, setPreloadErrorFlag] = useState(false);
    const [showComputeInstanceCreationModal, setShowComputeInstanceCreationModal] = useState(false);
    const [showComputeInstanceCreationSuccessFlag, setShowComputeInstanceCreationSuccessFlag] = useState(false);
    const [showComputeInstanceCreationFailureFlag, setShowComputeInstanceCreationFailureFlag] = useState(false);
    const [newComputeInstance, setNewComputeInstance] = useState(null);
    const [createComputeInstanceValidationErrorMessages, setCreateComputeInstanceValidationErrorMessages] = useState(null);
    const [showValidationErrorFlag, setShowValidationErrorFlag] = useState(false);

    useEffect(() => {
        async function retrieveInstanceCreationMetadata() {
            const user = await Auth.currentAuthenticatedUser();
            let token = user.signInUserSession.idToken.jwtToken;
            console.log("[API] Compute Meta Information Retrieval");
            const meta_response = await sendRequest(token, "/meta/compute-instances", "GET");
            if (meta_response['status'] == 'success') {
                setMetaData(meta_response['results']);
                setPreloadFlag(false);
            } else {
                setPreloadErrorFlag(true);
            }
        }
        let ignore = false;
        retrieveInstanceCreationMetadata();
        return () => {
            ignore = true;
        }
    }, []);
    let subContent = (
        <Segment>
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
    );

    if (metaData != null) {
        let subnetToVPCMapping = {};
        async function createComputeInstance(event) {
            event.preventDefault();
            const user = await Auth.currentAuthenticatedUser();
            let token = user.signInUserSession.idToken.jwtToken;
            let vpc_id = subnetToVPCMapping[event.target['subnet_id'].value];
            console.log("[API] Compute Instance Creation");
            console.log("[Data] Alias: " + event.target['alias'].value);
            console.log("[Data] Description: " + event.target['description'].value);
            console.log("[Data] VPC ID: " + vpc_id);
            console.log("[Data] Subnet ID: " + event.target['subnet_id'].value);
            console.log("[Data] AMI: " + event.target['ami'].value);
            console.log("[Data] Lifespan: " + event.target['lifespan'].value);

            let results = validateComputeInstance({
                'alias':  event.target['alias'].value,
                'description':  event.target['description'].value
            });

            if (results[1].length == 0) {
                setShowComputeInstanceCreationModal(true);
                const response = await sendRequest(token, "/compute", "POST", { "alias": event.target['alias'].value, "description": event.target['description'].value, "vpc_id": vpc_id, "subnet_id": event.target['subnet_id'].value, "ami": event.target['ami'].value, "life_span": event.target['lifespan'].value });
                if (response['status'] == 'success') {
                    setNewComputeInstance(response['results']);
                    setShowComputeInstanceCreationSuccessFlag(true);
                } else {
                    setShowComputeInstanceCreationFailureFlag(true);
                }
            } else {
                setShowComputeInstanceCreationModal(false);
                let validationMessages = [];
                for (let errorMessage of results[1]) {
                    validationMessages.push(<li>{errorMessage}</li>);
                }
                setCreateComputeInstanceValidationErrorMessages(validationMessages);
                setShowValidationErrorFlag(true);
                console.log("set to true");
            }

        }

        async function downloadPEMFile() {
            const element = document.createElement("a");
            const file = new Blob([newComputeInstance['keypair']['KeyMaterial']], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.target = "_blank";
            element.download = newComputeInstance['keypair']['KeyName'] + ".pem";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }

        let subnetListOptions = [];
        metaData['subnets'].forEach((subnet) => {
            subnetToVPCMapping[subnet['subnet-id']] = subnet['vpc-id'];
            subnetListOptions.push(
                <option value={subnet['subnet-id']}>{subnet['subnet-description']}</option>
            )
        });
        let amisListOptions = [];
        metaData['amis'].forEach((ami) => {
            amisListOptions.push(
                <option value={ami['ami-id']}>{ami['ami-family']}</option>
            )
        })
        let lifespanOptions = [];
        lifespanOptions.push(<option value="5" selected>5</option>);
        for (let i = 10; i <= 90; i += 5) {
            lifespanOptions.push(<option value={i}>{i}</option>);
        }

        let creationModal = <>
            <Modal
                onClose={() => { setShowComputeInstanceCreationModal(false) }}
                onOpen={() => { setShowComputeInstanceCreationModal(true) }}
                open={showComputeInstanceCreationModal}
                closeOnEscape={false}
                closeOnDimmerClick={false}
            >
                <Header content='Creating your compute instance' />
                <Modal.Content>
                    {showComputeInstanceCreationSuccessFlag ?
                        <>
                            <Message positive>
                                <Message.Header>Yayyyy!</Message.Header>
                                <p>Your instance has been successfully created. You can check the status of the creation in the subsequent page. Do give it a couple of minutes as it initialize. You can connect to the instance using the <b>Connect</b> feature!</p>
                            </Message>
                        </> : <>
                            {showComputeInstanceCreationFailureFlag ?
                                <>
                                    <Message negative>
                                        <Message.Header>Oh noess!</Message.Header>
                                        <p>We couldn't create the compute instance. Please slack us about it. So sorry about it!</p>
                                    </Message>
                                </> :
                                <>
                                    <Segment>
                                        <Dimmer active inverted>
                                            <Loader inverted>Creating compute instance...</Loader>
                                        </Dimmer>
                                        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                    </Segment>
                                </>
                            }
                        </>
                    }
                </Modal.Content>
                <Modal.Actions>
                    {showComputeInstanceCreationSuccessFlag ?
                        <>
                            <Button color='green' onClick={() => { navigate('/compute') }}>Done</Button>
                        </> : <>
                            {showComputeInstanceCreationFailureFlag ?
                                <>
                                    <Button color='grey' onClick={() => {
                                        setShowComputeInstanceCreationModal(false);
                                        setShowComputeInstanceCreationFailureFlag(false);
                                    }}>Okay</Button>
                                </> : null
                            }
                        </>
                    }
                </Modal.Actions>
            </Modal>
            {showValidationErrorFlag ? 
            <>
                <Message warning>
                    <Message.Header>Hmmmmmm!!</Message.Header>
                    <p>It seems like the instance you configured seems do not conform to our requirements!</p>
                    <ul>
                        {createComputeInstanceValidationErrorMessages}
                    </ul>
                </Message>
            </> : null}
        </>;

        subContent =
        <>
            <form class="ui segment form left aligned" onSubmit={createComputeInstance}>
                <div class="field">
                    <label>Alias (64 Characters)</label>
                    <Popup content={validator['alias']['helper']} trigger={<textarea rows="1" placeholder={validator.alias.placeholder} id="alias" maxLength={validator.alias.maxLength} required></textarea>} />
                </div>
                <div class="field">
                    <label>Description (256 Characters)</label>
                    <Popup content={validator['description']['helper']} trigger={<textarea rows="2" placeholder={validator.description.placeholder} id="description" maxLength={validator.description.maxLength} required></textarea>} />
                </div>
                <div class="three fields">
                    <div class="field">
                        <label>Subnets</label>
                        <select class="ui fluid dropdown" id="subnet_id" required>
                            {subnetListOptions}
                        </select>
                    </div>
                    <div class="field">
                        <label>Amazon Machine Image</label>
                        <select class="ui fluid dropdown" id="ami" required>
                            {amisListOptions}
                        </select>
                    </div>
                    <div class="field">
                            <label>Lifespan (days after launch)</label>
                            <select class="ui fluid dropdown" id="lifespan" required>
                                {lifespanOptions}
                            </select>
                    </div>
                </div>

                <div class="ui container center aligned">
                    <br></br>
                    <button class="ui green button">Launch now</button>
                </div>
            </form>
            {creationModal}
        </>
    }
    let content =
        <div class="ui grid">
            <div class="row">
                <div class="three wide column"></div>
                <div class="ten wide ui column">
                    <br></br><br></br><br></br>
                    <h3 class="ui center aligned dividing header">Launch New Compute Instance</h3>
                    <div class="ui message">
                        <div class="header">
                            Notes
                        </div>
                        <p>Cranefly supports only a limited set of Linux Operating Systems. Instances launched in CraneFly's enviornment are attached to a 8GB General Purpose SSD (gp3) volume indpendently. It is encrypted by AWS EBS service key. In addition, an IAM role (for Systems Manager) will be associated with the newly launched EC2 instance.</p>
                    </div>
                </div>
                <div class="three wide column"></div>
            </div>
            <div class="row">
                <div class="three wide column"></div>
                <div class="ten wide column">
                    {subContent}
                </div>
                <div class="three wide column"></div>
            </div>
        </div>;

    return content;
}

function ManageComputeInstanceView() {
    const { instanceId } = useParams();
    const navigate = useNavigate();
    const [computeInstance, setComputeInstance] = useState(null);
    const [securityGroupID, setSecurityGroupID] = useState(null);
    const [computeInstancesMeta, setComputeInstancesMeta] = useState(null);
    const [computeInstanceTags, setComputeInstanceTags] = useState(null);
    const [securityGroupIngressRules, setSecurityGroupIngressRules] = useState(null);
    const [securityGroupEgressRules, setSecurityGroupEgressRules] = useState(null);
    const [instanceUnavaliabilityFlag, setInstanceUnavaliabilityFlag] = useState(false);
    const [instanceLoadingErrorFlag, setInstanceLoadingErrorFlag] = useState(false);
    const [instanceNotFoundFlag, setInstanceNotFoundFlag] = useState(false);
    useEffect(() => {
        async function fetchComputeInstanceDetails() {
            const user = await Auth.currentAuthenticatedUser();
            let token = user.signInUserSession.idToken.jwtToken;
            if (!ignore) {
                console.log("[API] Fetching Compute Instance Details - " + instanceId);
                const compute_instance_response = await sendRequest(token, "/compute/" + instanceId, "GET");
                if (compute_instance_response['status'] == 'success' && compute_instance_response['results'].length > 0 && 'State' in compute_instance_response['results'][0] && 'Name' in compute_instance_response['results'][0]['State']) {
                    if (compute_instance_response['results'][0]['State']['Name'] == 'running') {
                        let security_group_id = compute_instance_response['results'][0]['NetworkInterfaces'][0]['Groups'][0]['GroupId'];
                        console.log("[API] Fetching Security Group Instance Details - " + security_group_id);
                        const security_group_instance_response = await sendRequest(token, "/security-groups/" + security_group_id, "GET");
                        console.log("[API] Compute Meta Information Retrieval");
                        const meta_response = await sendRequest(token, "/meta/compute-instances", "GET");
                        if (security_group_instance_response['status'] == 'success' && meta_response['status'] == 'success') {
                            setComputeInstance(compute_instance_response['results'][0]);
                            setComputeInstancesMeta(meta_response['results']);
                            setSecurityGroupID(security_group_id);
                            let securityGroupRules = security_group_instance_response['results'];
                            let ingressRules = [];
                            let egressRules = [];
                            securityGroupRules.forEach((rule) => {
                                if (rule['IsEgress']) {
                                    egressRules.push(rule);
                                } else {
                                    ingressRules.push(rule);
                                }
                            });
                            setSecurityGroupEgressRules(egressRules);
                            setSecurityGroupIngressRules(ingressRules);
                            let tags = {};
                            compute_instance_response['results'][0]['Tags'].forEach((tag) => {
                                tags[tag['Key']] = tag['Value'];
                            })
                            setComputeInstanceTags(tags);
                        } else {
                            setInstanceLoadingErrorFlag(true);
                        }
                    } else {
                        setInstanceUnavaliabilityFlag(true);
                    }
                } else {
                    setInstanceNotFoundFlag(true);
                }
            }
        }
        let ignore = false;
        fetchComputeInstanceDetails();
        return () => {
            ignore = true;
        }
    }, []);
    let mainContent = (
        <div class="ui grid">
            <div class="row">
                <div class="three wide column"></div>
                <div class="ten wide ui column">
                    <br></br><br></br><br></br>
                    <h3 class="ui center aligned dividing header">Compute Instance Dashboard</h3>
                </div>
                <div class="three wide column"></div>
            </div>
            <div class="row">
                <div class="three wide column"></div>
                <div class="ten wide column">
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                    </Segment>
                </div>
                <div class="three wide column"></div>
            </div>
        </div>

    );

    mainContent =
        <div class="ui grid">
            <div class="row">
                <div class="three wide column"></div>
                <div class="ten wide ui column">
                    <br></br><br></br><br></br>
                    <h3 class="ui center aligned dividing header">Compute Instance Dashboard <br></br> <i>{instanceId}</i></h3>
                </div>
                <div class="three wide column"></div>
            </div>
            <div class="row">
                <div class="three wide column"></div>
                <div class="ten wide column segment ui left aligned">
                    {instanceNotFoundFlag ?
                        <>
                            <Message negative>
                                <Message.Header>Sorryyyy!</Message.Header>
                                <p>We can't seem to find the specified instance for you!</p>
                            </Message>
                        </> :
                        <>
                            {instanceUnavaliabilityFlag ?
                                <>
                                    <Message negative>
                                        <Message.Header>Sorryyyy!</Message.Header>
                                        <p>The specified instance has been terminated</p>
                                    </Message>
                                </> :
                                <>
                                    {instanceLoadingErrorFlag ? <>
                                        <Message negative>
                                            <Message.Header>Sorryyyy!</Message.Header>
                                            <p>We can't seem to load the specified instance! If the issue perists, please Slack us!</p>
                                        </Message>
                                    </> : <>
                                        <Segment>
                                            <Dimmer active inverted>
                                                <Loader inverted>Loading</Loader>
                                            </Dimmer>
                                            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                        </Segment>
                                    </>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    if (computeInstance != null && computeInstanceTags != null && securityGroupIngressRules != null && securityGroupEgressRules != null) {
        let panes = [
            { menuItem: 'Ingress', render: () => <Tab.Pane attached={false}><SecurityGroupIngressRulesTable rules={securityGroupIngressRules} securityGroupID={securityGroupID} /></Tab.Pane> },
            { menuItem: 'Egress', render: () => <Tab.Pane attached={false}><SecurityGroupEgressRulesTable rules={securityGroupEgressRules} securityGroupID={securityGroupID} /></Tab.Pane> },
        ]

        let subnetsMapping = {};
        computeInstancesMeta['subnets'].forEach((subnet) => {
            subnetsMapping[subnet['subnet-id']] = subnet['subnet-description'];
        });

        let amisMapping = {};
        computeInstancesMeta['amis'].forEach((ami) => {
            amisMapping[ami['ami-id']] = ami['ami-family'];
        })
        let lifeSpanOptions = [];
        for (let i = 5; i <= 90; i += 5) {
            if (parseInt(computeInstanceTags['Lifespan']) == i) {
                lifeSpanOptions.push(<option value={i} selected>{i}</option>);
            } else {
                lifeSpanOptions.push(<option value={i}>{i}</option>);
            }
        }

        let launchDate = new Date(computeInstance['LaunchTime']);
        let elapsedTimeDisplay = generatedElapsedTimeDisplaySinceLaunch(launchDate);
        let launchDateDisplay = launchDate.toDateString();
        let daysLeftDisplay = generatedExpiryTimeDisplay(launchDate, parseInt(computeInstanceTags['Lifespan']));
        mainContent =
            <div class="ui grid">
                <div class="row">
                    <div class="three wide column"></div>
                    <div class="ten wide ui column">
                        <br></br><br></br><br></br>
                        <h3 class="ui center aligned dividing header">Compute Instance Dashboard <br></br> <i>{computeInstance['InstanceId']} ({computeInstanceTags['Alias']})</i></h3>
                    </div>
                    <div class="three wide column"></div>
                </div>
                <div class="row">
                    <div class="three wide column"></div>
                    <div class="ten wide column segment ui left aligned">
                        <h3 class="ui dividing header">Instance Details</h3>
                        <p>Launched {elapsedTimeDisplay} ago, on {launchDateDisplay}<br></br><i>Automatically terminating in <b>{daysLeftDisplay}</b></i></p>
                        <form class="ui form" id={computeInstance['InstanceId']}>
                            <div class="two fields">
                                <div class="field">
                                    <label>Status</label>
                                    <p>{computeInstance['State']['Name'].toUpperCase()}</p>
                                </div>
                                <div class="field">
                                    <label>Instance Type</label>
                                    <p>{computeInstance['InstanceType']}</p>
                                </div>
                                <div class="field">
                                    <label>EBS Status</label>
                                    <p>{computeInstance['BlockDeviceMappings'][0]['Ebs']['Status'].toUpperCase()}</p>
                                </div>
                                <div class="field">
                                    <label>Architecture</label>
                                    <p>{computeInstance['Architecture']}</p>
                                </div>
                            </div>
                            <div class="four fields">
                                <div class="field">
                                    <label>Public IPv4</label>
                                    <p>{computeInstance['PublicIpAddress']}</p>
                                </div>
                                <div class="field">
                                    <label>Public IPv4 DNS</label>
                                    <a href={computeInstance['PublicDnsName']} target="_blank">{computeInstance['PublicDnsName']}</a>
                                </div>
                                <div class="field">
                                    <label>Private IPv4</label>
                                    <p>{computeInstance['PrivateIpAddress']}</p>
                                </div>
                                <div class="field">
                                    <label>Private IPv4 DNS</label>
                                    <a href={computeInstance['PrivateDnsName']} target="_blank">{computeInstance['PrivateDnsName']}</a>
                                </div>
                            </div>
                            <div class="field">
                                <label>Subnet</label>
                                <p>{computeInstance['NetworkInterfaces'][0]['SubnetId']} - {subnetsMapping[computeInstance['NetworkInterfaces'][0]['SubnetId']]}</p>
                            </div>
                            <div class="three fields">
                                <div class="field">
                                    <label>Amazon Machine Image</label>
                                    <p>{amisMapping[computeInstance['ImageId']]}</p>
                                </div>
                                <div class="field">
                                    <label>Keypair</label>
                                    <p>{computeInstance['KeyName']}</p>
                                </div>
                                <div class="field">
                                    <label>Lifespan (days after launch)</label>
                                    <select id="lifespan">
                                        {lifeSpanOptions}
                                    </select>
                                </div>
                            </div>
                            <div class="field">
                                <label>Alias (64 Characters)</label>
                                <Popup content={validator['alias']['helper']} trigger={<textarea rows="1" placeholder={validator.alias.placeholder} defaultValue={computeInstanceTags['Alias']} id='alias' maxLength={validator.alias.maxLength}></textarea>} />

                            </div>
                            <div class="field">
                                <label>Description (256 Characters)</label>
                                <Popup content={validator['description']['helper']} trigger={<textarea rows="2" placeholder={validator.description.placeholder} defaultValue={computeInstanceTags['Description']} id='description' maxLength={validator.description.maxLength}></textarea>} />
                            </div>

                        </form>
                        <div class="ui right aligned container">
                            <br></br>
                            <DeleteComputeInstanceModal computeInstance={computeInstance} />
                            <Button color='teal' onClick={() => { navigate('session') }} >Connect</Button>
                            <UpdateComputeInstanceModal computeInstance={computeInstance} />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="three wide column"></div>
                    <div class="ten wide column ui segment left aligned">
                        <h3 class="ui dividing header">Security Group Configurations</h3>
                        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                    </div>
                    <div class="three wide column"></div>
                </div>
            </div>
    }
    return mainContent;
}

function DeleteComputeInstanceModal(props) {

    const [computeInstanceDeleteModalFlag, setComputeInstanceDeleteModalFlag] = useState(false)
    const [deleteComputeInstanceProgressFlag, setDeleteComputeInstanceProgressFlag] = useState(false)
    const [deleteComputeInstanceErrorFlag, setDeleteComputeInstanceErrorFlag] = useState(false)
    const [deleteComputeInstanceSuccessFlag, setDeleteComputeInstanceSuccessFlag] = useState(false)

    async function deleteComputeInstance(computeInstanceID) {
        setDeleteComputeInstanceProgressFlag(true);
        const user = await Auth.currentAuthenticatedUser();
        let token = user.signInUserSession.idToken.jwtToken;
        const response = await sendRequest(token, "/compute/" + computeInstanceID, "DELETE");
        console.log("[API] Compute Instance Deletion - " + computeInstanceID);
        if (response['status'] == 'success') {
            setDeleteComputeInstanceSuccessFlag(true);
            setDeleteComputeInstanceProgressFlag(false);
        } else {
            setDeleteComputeInstanceProgressFlag(false);
            setDeleteComputeInstanceErrorFlag(true);
        }
    }
    return (<Modal
        onClose={() => setComputeInstanceDeleteModalFlag(false)}
        onOpen={() => setComputeInstanceDeleteModalFlag(true)}
        open={computeInstanceDeleteModalFlag}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        trigger={<Button color='grey'>Terminate</Button>}
    >
        <Modal.Header>Confirmation</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Header>Terminating Compute Instance</Header>
                <p>Terminating the compute instance will terminate the instance immediately from CraneFly's enviornment.<br></br><br></br>Are you sure you want to terminate <b>{props.computeInstance['InstanceId']}</b>?</p>
                {deleteComputeInstanceErrorFlag ?
                    <Message negative>
                        <Message.Header>Sorryyyy!</Message.Header>
                        <p>An error has occurred when terminating the compute instance. Please raise a support ticket.</p>
                    </Message> : null
                }

                {deleteComputeInstanceSuccessFlag ?
                    <Message positive>
                        <Message.Header>Yayyyy!</Message.Header>
                        <p>Your compute instance has been successfully terminated</p>
                    </Message> : null
                }
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            {deleteComputeInstanceSuccessFlag ?
                <Button content="Done" color='green' onClick={() => {window.location = "/compute"}} /> : <>
                    {deleteComputeInstanceErrorFlag ? <Button color='grey' onClick={() => {
                        setComputeInstanceDeleteModalFlag(false);
                        setDeleteComputeInstanceErrorFlag(false);
                    }}>Okay</Button> :
                        <>
                            <Button color='grey' onClick={() => {
                                setComputeInstanceDeleteModalFlag(false);
                                setDeleteComputeInstanceErrorFlag(false);
                            }}
                                disabled={deleteComputeInstanceProgressFlag}>Nope</Button>
                            {deleteComputeInstanceProgressFlag ?
                                <Button loading color='red'>loading</Button> :
                                <Button content="Yes, terminate it!" color='red' onClick={() => deleteComputeInstance(props.computeInstance['InstanceId'])} />}
                        </>
                    }

                </>
            }
        </Modal.Actions>
    </Modal>);
}

function UpdateComputeInstanceModal(props) {
    const [showUpdateComputeInstanceModal, setShowUpdateComputeInstanceModal] = useState(false);
    const [updateComputeInstanceModalSucessFlag, setUpdateComputeInstanceModalSucessFlag] = useState(false);
    const [updateComputeInstanceModalErrorFlag, setUpdateComputeInstanceModalErrorFlag] = useState(false);
    const [updateComputeInstanceValidationErrorMessages, setUpdateComputeInstanceValidationErrorMessages] = useState(null);
    const [showValidationErrorFlag, setShowValidationErrorFlag] = useState(false);

    async function updateComputeInstance(computeInstanceID) {
        var form = document.getElementById(computeInstanceID);
        const user = await Auth.currentAuthenticatedUser();
        let token = user.signInUserSession.idToken.jwtToken;
        console.log("[API] Update Compute Instance - " + computeInstanceID);
        console.log("[Data] Alias: " + form['alias'].value);
        console.log("[Data] Description: " + form['description'].value);
        console.log("[Data] Lifespan: " + form['lifespan'].options[form['lifespan'].selectedIndex].value);

        let results = validateComputeInstance({
            'alias': form['alias'].value,
            'description': form['description'].value
        })
        if (results[1].length == 0) {
            setShowUpdateComputeInstanceModal(true);
            const response = await sendRequest(token, "/compute/" + computeInstanceID, "PATCH", { "life_span": form['lifespan'].options[form['lifespan'].selectedIndex].value, "alias": results[0]['alias'], "description": results[0]['description'] });
            if (response['status'] == 'success') {
                setUpdateComputeInstanceModalSucessFlag(true);
            } else {
                setUpdateComputeInstanceModalErrorFlag(true);
            }
        } else {
            setShowUpdateComputeInstanceModal(false);
            console.log('show validation');
            let validationMessages = [];
            for (let errorMessage of results[1]) {
                validationMessages.push(<li>{errorMessage}</li>);
            }
            setUpdateComputeInstanceValidationErrorMessages(validationMessages);
            setShowValidationErrorFlag(true);
        }


    }
    let modal =
        <>
            <Modal
                onClose={() => { setShowUpdateComputeInstanceModal(false) }}
                onOpen={() => {
                    setShowUpdateComputeInstanceModal(true);
                    updateComputeInstance(props.computeInstance['InstanceId']);
                }}
                open={showUpdateComputeInstanceModal}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                trigger={<Button color='blue'>Save</Button>}
            >
                <Header content='Saving the compute instance details' />
                <Modal.Content>
                    {
                        updateComputeInstanceModalSucessFlag ?
                            <>
                                <Message positive>
                                    <Message.Header>Yayyyy!</Message.Header>
                                    <p>Your compute instance details has been created!</p>
                                </Message>
                            </> : <>
                                {updateComputeInstanceModalErrorFlag ?
                                    <>
                                        <Message negative>
                                            <Message.Header>Oh noess!</Message.Header>
                                            <p>We couldn't save the new changes!. Please raise a support ticket. So sorry about it!</p>
                                        </Message>
                                    </> :
                                    <Segment>
                                        <Dimmer active inverted>
                                            <Loader inverted>Loading</Loader>
                                        </Dimmer>
                                        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                    </Segment>
                                }
                            </>
                    }
                </Modal.Content>
                <Modal.Actions>
                    {updateComputeInstanceModalSucessFlag ?
                        <Button color='green' onClick={() => { window.location.reload(false); }}>Done</Button> : <>
                            {updateComputeInstanceModalErrorFlag ?
                                <Button color='grey' onClick={() => {
                                    setShowUpdateComputeInstanceModal(false);
                                    setUpdateComputeInstanceModalErrorFlag(false);
                                }}>Okayy</Button>
                                : null
                            }
                        </>
                    }
                </Modal.Actions>
            </Modal>
            <br></br>
            <br></br>

            <div class="ui container left aligned">
                {showValidationErrorFlag ?
                    <>
                        <Message warning>
                            <Message.Header>Hmmmmmm!!</Message.Header>
                            <p>It seems like the instance you configured seems do not conform to our requirements!</p>
                            <ul>
                                {updateComputeInstanceValidationErrorMessages}
                            </ul>
                        </Message>
                        <UpdateInstanceValidationMessagesFocusArea></UpdateInstanceValidationMessagesFocusArea>
                    </>
                    : null
                }
            </div>
        </>
    return modal;
}

// Has to be abstracted to two separate components for the Tabs to render properly
function SecurityGroupIngressRulesTable(props) {
    return <SecurityGroupRulesTable rules={props.rules} ruleType="ingress" securityGroupID={props.securityGroupID} />;
}

function SecurityGroupEgressRulesTable(props) {
    return <SecurityGroupRulesTable rules={props.rules} ruleType="egress" securityGroupID={props.securityGroupID} />;
}

function SecurityGroupRulesTable(props) {
    let content = <>
        <Message warning>
            <Message.Header>Security Group Advisory</Message.Header>
            <p>In AWS, Security Groups controls the network access to your EC2 instance. Please restrict the network traffic to what neccessary for the EC2 to function properly. We strongly discourage the use of wide-port ranges and CIDR blocks as it may open additional holes in our defense posture.</p>
        </Message>
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={4}>CIDR</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Start Port</Table.HeaderCell>
                    <Table.HeaderCell width={2}>End Port</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Protocol</Table.HeaderCell>
                    <Table.HeaderCell width={6}>Description</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <SecurityGroupRuleTableRows rules={props.rules} ruleType={props.ruleType} />
            </Table.Body>
        </Table>
        <div class="ui container right aligned">
            <NewSecurityRuleModal ruleType={props.ruleType} securityGroupID={props.securityGroupID} />
            <SaveRulesModal ruleType={props.ruleType} securityGroupID={props.securityGroupID} rules={props.rules} />
        </div>
    </>;
    return content;
}

function SecurityGroupRuleTableRows(props) {
    let contentRows = [];
    if (props.rules.length > 0) {
        props.rules.forEach((rule) => {
            contentRows.push(<SecurityGroupRuleTableRow rule={rule} ruleType={props.ruleType} />);
            contentRows.push(<SecurityGroupRuleTableRowAction rule={rule} />)
        });
    } else {
        contentRows.push(<Table.Row><Table.Cell colSpan="5" textAlign='center'><p>No rule(s) created</p></Table.Cell></Table.Row>)
    }
    
    return contentRows;
}

function ComputeInstanceTableActionRow(props) {
    const navigate = useNavigate();
    let content = (
        <Table.Row>
            <Table.Cell colSpan="3" textAlign='right'>
                <DeleteComputeInstanceModal computeInstance={props.computeInstance} />
                <Button color='blue' content='Manage' onClick={() => { navigate("/compute/" + props.computeInstance['InstanceId']) }} />
                <Button color="teal" content="Connect" onClick={() => { navigate("/compute/" + props.computeInstance['InstanceId'] + "/session") }} />
            </Table.Cell>
        </Table.Row>

    );
    return content
}

function SecurityGroupRuleTableRow(props) {
    let startPort = props.rule['FromPort'] == '-1' ? 0 : props.rule['FromPort'];
    let endPort = props.rule['ToPort'] == '-1' ? 65535 : props.rule['ToPort'];
    let protocolSelectionOptions = [];
    protocolSelection.forEach((protocol) => {
        protocolSelectionOptions.push((<option value={protocol['value']} selected={protocol['value'] == props.rule['IpProtocol'] ? "selected" : null}>{protocol['text']}</option>));
    });
    let content =
        <TableRow>
            <Table.Cell>
                <Form id={props.rule['SecurityGroupRuleId']} onSubmit={(event) => {
                    event.preventDefault();
                }}></Form>
                <Form>
                    <Form.Field>
                        <Popup content={validator['cidr']['helper']} trigger={<Form.Input form={props.rule['SecurityGroupRuleId']} placeholder={validator.cidr.placeholder} defaultValue={props.rule['CidrIpv4']} fluid={true} required={true} maxLength={validator.cidr.maxLength} id={props.rule['SecurityGroupRuleId'] + '-cidr'} />} />
                    </Form.Field>
                </Form>
            </Table.Cell>
            <Table.Cell>
                <Form>
                    <Form.Field>
                        <Popup content={validator['port']['helper']} trigger={<Form.Input form={props.rule['SecurityGroupRuleId']} placeholder="Start Port" maxLength={validator.port.maxLength} defaultValue={startPort} fluid={true} required={true} id={props.rule['SecurityGroupRuleId'] + '-startPort'} />} />
                    </Form.Field>
                </Form>
            </Table.Cell>
            <Table.Cell>
                <Form>
                    <Form.Field>
                        <Popup content={validator['port']['helper']} trigger={<Form.Input form={props.rule['SecurityGroupRuleId']} placeholder="End Port" maxLength={validator.port.maxLength} defaultValue={endPort} fluid={true} required={true} id={props.rule['SecurityGroupRuleId'] + '-endPort'} />} />
                    </Form.Field>
                </Form>
            </Table.Cell>
            <Table.Cell>
                <Form>
                    <Form.Field>
                        <select form={props.rule['SecurityGroupRuleId']} id={props.rule['SecurityGroupRuleId'] + "-protocol"}>
                            {protocolSelectionOptions}
                        </select>
                    </Form.Field>
                </Form>
            </Table.Cell>
            <Table.Cell>
                <Form>
                    <Form.Field>
                        <Popup content={validator['description']['helper']} trigger={<Form.Input form={props.rule['SecurityGroupRuleId']} placeholder={validator.description.placeholder} maxLength={validator.description.maxLength} defaultValue={'Description' in props.rule ? props.rule['Description'] : ''} fluid={true} required={true} id={props.rule['SecurityGroupRuleId'] + '-description'} />} />
                    </Form.Field>
                </Form>
            </Table.Cell>
        </TableRow>
    return content;
}

function NewSecurityRuleModal(props) {
    const [showNewSecurityGroupRuleModal, setShowNewSecurityGroupRuleModal] = useState(false);
    const [creatingSecurityGroupRuleFlag, setCreatingSecurityGroupRuleFlag] = useState(false);
    const [createSecurityGroupRuleSuccessFlag, setCreateSecurityGroupRuleSuccessFlag] = useState(false);
    const [createSecurityGroupRuleErrorFlag, setCreateSecurityGroupRuleErrorFlag] = useState(false);
    const [createRuleValidationErrorMessages, setCreateRuleValidationErrorMessages] = useState(null);
    const [showValidationErrorFlag, setShowValidationErrorFlag] = useState(false);

    async function createSecurityGroupRuleInstance(event, securityGroupID, ruleType) {
        event.preventDefault();
        setShowNewSecurityGroupRuleModal(true);
        const user = await Auth.currentAuthenticatedUser();
        let token = user.signInUserSession.idToken.jwtToken;
        console.log("[API] Security Group Rule Creation - " + securityGroupID);
        console.log("[Data] Mode: " + ruleType);
        console.log("[Data] CIDR: " + event.target['cidr'].value);
        console.log("[Data] Start Port: " + event.target['startPort'].value);
        console.log("[Data] End Port: " + event.target['endPort'].value);
        console.log("[Data] Protocol: " + event.target['protocol'].value);
        console.log("[Data] Description: " + event.target['description'].value);

        let results = validateSecurityGroupRule({
            'cidr': event.target['cidr'].value,
            'startPort': event.target['startPort'].value,
            'endPort': event.target['endPort'].value,
            'protocol': event.target['protocol'].value,
            'description': event.target['description'].value
        });

        if (results[1].length == 0) {
            setCreatingSecurityGroupRuleFlag(true);
            const response = await sendRequest(token, "/security-groups/" + securityGroupID + "/" + ruleType, "POST", { "cidr-ip": event.target['cidr'].value, "from-port": event.target['startPort'].value, "to-port": event.target['endPort'].value, "ip-protocol": event.target['protocol'].value, "description": event.target['description'].value });
            setCreatingSecurityGroupRuleFlag(false);
            if (response['status'] == 'success') {
                setCreateSecurityGroupRuleSuccessFlag(true);
            } else {
                setCreateSecurityGroupRuleErrorFlag(true);
            }
        } else {
            // Validation Errors
            setShowValidationErrorFlag(true);
            let validationMessages = [];
            for (let errorMessage of results[1]) {
                validationMessages.push(<li>{errorMessage}</li>);
            }
            setCreateRuleValidationErrorMessages(validationMessages);

        }


    }
    let protocolSelectionOptions = [];
    protocolSelection.forEach((protocol) => {
        protocolSelectionOptions.push((<option value={protocol['value']}>{protocol['text']}</option>));
    });
    let modal =
        <>
            <Modal
                onClose={() => {
                    props.setShowNewSecurityGroupRuleModal(false);
                }}
                onOpen={() => setShowNewSecurityGroupRuleModal(true)}
                open={showNewSecurityGroupRuleModal}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                trigger={<Button color='green'>{"Create new " + props.ruleType + " rule"}</Button>}
            >
                <Header content={'Create a new ' + props.ruleType + ' rule'} />
                <Modal.Content>
                    {creatingSecurityGroupRuleFlag ?
                        <Segment>
                            <Dimmer active inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>
                            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                        </Segment> :
                        createSecurityGroupRuleSuccessFlag ?
                            <>
                                <Message positive>
                                    <Message.Header>Yayyyy!</Message.Header>
                                    <p>Your new security group rule has been created!</p>
                                </Message>
                            </> : <>
                                {createSecurityGroupRuleErrorFlag ?
                                    <>
                                        <Message negative>
                                            <Message.Header>Oh noess!</Message.Header>
                                            <p>We couldn't create the security group rule!. Please raise a support ticket. So sorry about it!</p>
                                        </Message>
                                    </> :
                                    <Form id={props.securityGroupID} onSubmit={(event) => { createSecurityGroupRuleInstance(event, props.securityGroupID, props.ruleType) }}>
                                        <Form.Field>
                                            <Popup content={validator['cidr']['helper']} trigger={<Form.Input label="CIDR" placeholder={validator.cidr.placeholder} maxLength={validator.cidr.maxLength} fluid={true} required={true} id='cidr' />} />
                                        </Form.Field>
                                        <Form.Field>
                                            <Popup content={validator['port']['helper']} trigger={<Form.Input label="Start Port" placeholder={validator.port.placeholder} maxLength={validator.port.maxLength} id='startPort' fluid={true} required={true} />} />
                                        </Form.Field>
                                        <Form.Field>
                                            <Popup content={validator['port']['helper']} trigger={<Form.Input label="End Port" placeholder={validator.port.placeholder} maxLength={validator.port.maxLength} id='endPort' fluid={true} required={true} />} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label required>Protocol</label>
                                            <select id="protocol">
                                                {protocolSelectionOptions}
                                            </select>
                                        </Form.Field>
                                        <Form.Field>
                                            <Popup content={validator['description']['helper']} trigger={<Form.Input label="Description" placeholder={validator.description.placeholder} maxLength={validator.description.maxLength} id='description' fluid={true} required={true} />} />
                                        </Form.Field>
                                    </Form>
                                }

                                {showValidationErrorFlag ?
                                    <>
                                        <Message warning>
                                            <Message.Header>Hmmmmmm!!</Message.Header>
                                            <p>It seems like the rule you configured seems do not conform to our requirements!</p>
                                            <ul>
                                                {createRuleValidationErrorMessages}
                                            </ul>
                                        </Message>
                                    </>
                                    : null
                                }

                            </>

                    }

                </Modal.Content>
                <Modal.Actions>
                    {creatingSecurityGroupRuleFlag ?
                        <Button loading color='green'>loading</Button> : <>
                            {createSecurityGroupRuleSuccessFlag ?
                                <>
                                    <Button color='green' onClick={() => {
                                        setShowNewSecurityGroupRuleModal(false);
                                        window.location.reload(false);
                                    }}>Done</Button>
                                </> :
                                createSecurityGroupRuleErrorFlag ?
                                    <Button color='grey' onClick={() => {
                                        setShowNewSecurityGroupRuleModal(false);
                                        setCreatingSecurityGroupRuleFlag(false);
                                        setCreateSecurityGroupRuleErrorFlag(false);
                                    }}>Okayy</Button>
                                    :
                                    <>
                                        <Button color='grey' onClick={() => {
                                            setShowNewSecurityGroupRuleModal(false);
                                        }}>Nahh! Skip it!</Button>
                                        <Button color='green' type="submit" form={props.securityGroupID} >Create Rule</Button>
                                    </>
                            }
                        </>
                    }
                </Modal.Actions>
            </Modal>
        </>
    return modal;
}

function validateComputeInstance(instance) {
    let validationMessages = [];
    if (!validator.alias.isValid(instance.alias)) {
        validationMessages.push(validator.alias.message);
    }
    if (!validator.description.isValid(instance.description)) {
        validationMessages.push(validator.description.message);
    }
    return [{
        'alias': instance.alias,
        'description': instance.description,
    }, Array.from(validationMessages)];
}

function validateSecurityGroupRule(rule) {
    let validationMessages = new Set();
    if (!validator.cidr.isValid(rule.cidr)) {
        validationMessages.add(validator.cidr.message);
    }
    if (!validator.port.isValid(rule.startPort)) {
        validationMessages.add(validator.port.message);
    }
    if (!validator.port.isValid(rule.endPort)) {
        validationMessages.add(validator.port.message);
    }
    if (!validator.description.isValid(rule.description)) {
        validationMessages.add(validator.description.message);
    }
    if (!validator.portChecker.isValidPortCombination(rule.startPort, rule.endPort)) {
        validationMessages.add(validator.portChecker.message);
    }
    return [{
        'cidr': rule.cidr,
        'startPort': rule.startPort,
        'endPort': rule.endPort,
        'protocol': rule.protocol,
        'description': rule.description
    }, Array.from(validationMessages)];

}

function extractAndValidateRules(rules) {
    let validationMessages = [];
    let userSuppliedRules = {};
    for (let rule of rules) {
        let cidr = document.getElementById(rule['SecurityGroupRuleId'] + '-cidr').value;
        let startPort = document.getElementById(rule['SecurityGroupRuleId'] + '-startPort').value;
        let endPort = document.getElementById(rule['SecurityGroupRuleId'] + '-endPort').value;
        let protocol = document.getElementById(rule['SecurityGroupRuleId'] + '-protocol').value;
        let description = document.getElementById(rule['SecurityGroupRuleId'] + '-description').value;

        let results = validateSecurityGroupRule({
            'cidr': cidr,
            'startPort': startPort,
            'endPort': endPort,
            'protocol': protocol,
            'description': description
        })

        userSuppliedRules[rule['SecurityGroupRuleId']] = results[0];
        validationMessages = [].concat(validationMessages, results[1]);
    };

    return {
        'validationMessages': new Set(validationMessages),
        'userSuppliedRules': userSuppliedRules
    };


}

function SaveRulesModal(props) {
    const [showSaveRulesModal, setShowSaveRulesModal] = useState(false);
    const [saveRulesSuccessFlag, setSaveRulesSuccessFlag] = useState(false);
    const [saveRulesCompletionFlag, setSaveRulesCompletionFlag] = useState(false);
    const [saveRulesValidationErrorMessages, setSaveRulesValidationErrorMessages] = useState(null);
    const [showValidationErrorFlag, setShowValidationErrorFlag] = useState(false);
    const validationMessagesContainerRef = useRef(null);
    async function saveSecurityGroupRules(securityGroupID, ruleType, rules) {
        let validatedUserSuppliedRules = extractAndValidateRules(rules);
        let validationMessagesEntries = Array.from(validatedUserSuppliedRules['validationMessages'].entries());
        if (validationMessagesEntries.length > 0) {
            // User supplied inputs validation failed
            // Show error messages
            let validationMessages = [];
            for (let errorMessage of validationMessagesEntries) {
                validationMessages.push(<li>{errorMessage[0]}</li>);
            }
            setSaveRulesValidationErrorMessages(validationMessages);
            setShowValidationErrorFlag(true);
            setShowSaveRulesModal(false);
        } else {
            // Proceed to update the security groups
            setShowSaveRulesModal(true);
            let success = true;
            const user = await Auth.currentAuthenticatedUser();
            let token = user.signInUserSession.idToken.jwtToken;
            let rulesId = Object.keys(validatedUserSuppliedRules['userSuppliedRules']);
            for (let ruleId of rulesId) {
                let rule = validatedUserSuppliedRules['userSuppliedRules'][ruleId];
                const response = await sendRequest(token, "/security-groups/" + securityGroupID + "/" + ruleType + "/" + ruleId, "PATCH", { "cidr-ip": rule['cidr'], "from-port": rule['startPort'], "to-port": rule['endPort'], "ip-protocol": rule['protocol'], "description": rule['description'] });
                if (response['status'] != 'success') {
                    success = false;
                }
            }
            setSaveRulesCompletionFlag(true);
            if (success) {
                setSaveRulesSuccessFlag(true);
            } else {
                setSaveRulesSuccessFlag(false);
            }

        }

    }

    let modal =
        <>
            <Modal
                onClose={() => {
                    setShowSaveRulesModal(false);
                }}
                onOpen={() => {
                    setShowSaveRulesModal(true);
                    saveSecurityGroupRules(props.securityGroupID, props.ruleType, props.rules);
                }}
                open={showSaveRulesModal}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                trigger={<Button color='blue'>{"Save " + props.ruleType + " rules"}</Button>}
            >
                <Header content={"Saving " + props.ruleType + " rules"} />
                <Modal.Content>
                    {saveRulesCompletionFlag ? <>
                        {saveRulesSuccessFlag ? <>
                            <Message positive>
                                <Message.Header>Yayyyy!</Message.Header>
                                <p>Yesss! We managed to save the rules!</p>
                            </Message>
                        </> : <>
                            <Message negative>
                                <Message.Header>Hmmmm!</Message.Header>
                                <p>Something went wrong, we couldn't save the rules!</p>
                            </Message>
                        </>}
                    </> : <>
                        <Segment>
                            <Dimmer active inverted>
                                <Loader inverted>Saving</Loader>
                            </Dimmer>
                            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                        </Segment>
                    </>}
                </Modal.Content>
                <Modal.Actions>
                    {saveRulesCompletionFlag ? <>
                        {saveRulesSuccessFlag ? <>
                            <Button color='green' onClick={() => { window.location.reload(false); }}>Done</Button>
                        </> : <>
                            <Button color='grey' onClick={() => {
                                setShowSaveRulesModal(false);
                                setSaveRulesCompletionFlag(false);
                                setSaveRulesSuccessFlag(false);
                            }}>Okay</Button>
                        </>}
                    </> : null}

                </Modal.Actions>
            </Modal>
            <br></br>
            <br></br>

            <div class="ui container left aligned">
                {showValidationErrorFlag ?
                    <>
                        <Message warning>
                            <Message.Header>Hmmmmmm!!</Message.Header>
                            <p>It seems like the rules you configured seems do not conform to our requirements!</p>
                            <ul>
                                {saveRulesValidationErrorMessages}
                            </ul>
                        </Message>
                        <SecurityGroupRulesValidationMessagesFocusArea></SecurityGroupRulesValidationMessagesFocusArea>
                    </>
                    : null
                }
            </div>

        </>
    return modal;
}

function SecurityGroupRulesValidationMessagesFocusArea() {
    useEffect(() => {
        window.scrollBy(0, 1000);
    }, []);
}

function UpdateInstanceValidationMessagesFocusArea() {
    useEffect(() => {
        window.scrollBy(0, 100);
    }, []);
}

function SecurityGroupRuleTableRowAction(props) {
    const [showDeleteSecurityGroupRuleInstanceModal, setShowDeleteSecurityGroupRuleInstanceModal] = useState(false);
    const [deleteSecurityGroupRuleInstanceSuccessFlag, setDeleteSecurityGroupRuleInstanceSuccessFlag] = useState(false);
    const [deleteSecurityGroupRuleInstanceErrorFlag, setDeleteSecurityGroupRuleInstanceErrorFlag] = useState(false);

    async function deleteSecurityGroupRuleInstance(securityGroupID, securityGroupRuleID, mode) {
        const user = await Auth.currentAuthenticatedUser();
        let token = user.signInUserSession.idToken.jwtToken;
        console.log("[API] Security Group Rule Instance Deletion - " + securityGroupRuleID);
        const response = await sendRequest(token, "/security-groups/" + securityGroupID + "/" + mode + "/" + securityGroupRuleID, "DELETE");
        if (response['status'] == 'success') {
            setDeleteSecurityGroupRuleInstanceSuccessFlag(true);
        } else {
            setDeleteSecurityGroupRuleInstanceErrorFlag(true)
        }
    }
    let ruleType = props.rule['IsEgress'] ? 'egress' : 'ingress';
    let content =
        <Table.Row>
            <Table.Cell colSpan={5} textAlign='right'>
                <Modal
                    onClose={() => { setShowDeleteSecurityGroupRuleInstanceModal(false) }}
                    onOpen={() => {
                        setShowDeleteSecurityGroupRuleInstanceModal(true);
                        deleteSecurityGroupRuleInstance(props.rule['GroupId'], props.rule['SecurityGroupRuleId'], ruleType);
                    }}
                    open={showDeleteSecurityGroupRuleInstanceModal}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    trigger={<Button color='grey'>Delete</Button>}
                >
                    <Header content={'Deleting the ' + ruleType + ' rule'} />
                    <Modal.Content>
                        {deleteSecurityGroupRuleInstanceSuccessFlag ?
                            <>
                                <Message positive>
                                    <Message.Header>Yayyyy!</Message.Header>
                                    <p>Yesss! We managed to delete the security group rule!</p>
                                </Message>
                            </> : <>
                                {deleteSecurityGroupRuleInstanceErrorFlag ?
                                    <>
                                        <Message negative>
                                            <Message.Header>Oh noess!</Message.Header>
                                            <p>We couldn't delete the security group rule. Please raise a support ticket. So sorry about it!</p>
                                        </Message>
                                    </> :
                                    <>
                                        <Segment>
                                            <Dimmer active inverted>
                                                <Loader inverted>{'Deleting the ' + ruleType + ' rule'}</Loader>
                                            </Dimmer>
                                            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                        </Segment>
                                    </>
                                }
                            </>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        {deleteSecurityGroupRuleInstanceSuccessFlag ?
                            <>
                                <Button color='green' onClick={() => { window.location.reload(false); }}>Done</Button>
                            </> : <>
                                {deleteSecurityGroupRuleInstanceErrorFlag ?
                                    <>
                                        <Button color='grey' onClick={() => {
                                            setShowDeleteSecurityGroupRuleInstanceModal(false);
                                            setDeleteSecurityGroupRuleInstanceErrorFlag(false);
                                        }}>Okay</Button>
                                    </> : null
                                }
                            </>
                        }
                    </Modal.Actions>
                </Modal>
            </Table.Cell>
        </Table.Row>
    return content
}

function generatedExpiryTimeDisplay(launchDate, lifeSpan) {
    let expiryDate = new Date();
    expiryDate.setDate(launchDate.getDate() + lifeSpan)
    return generateDateElapsedDisplay(Date.now(), expiryDate);
}

function generatedElapsedTimeDisplaySinceLaunch(launchDate) {
    let currentDate = Date.now();
    return generateDateElapsedDisplay(launchDate, currentDate) + " ago";
}

function generateDateElapsedDisplay(start_date, end_date) {
    let elapsedTime = Math.abs(Math.round((end_date - start_date) / 1000 / (60 * 60)));
    let elapsedTimeDisplay = elapsedTime + " hours";
    if (elapsedTime > 24) {
        elapsedTime /= 24;
        elapsedTime = Math.abs(Math.round(elapsedTime));
        elapsedTimeDisplay = elapsedTime + " days";
    }
    return elapsedTimeDisplay;
}

export { ListComputeInstancesView, NewComputeInstanceView, ManageComputeInstanceView };